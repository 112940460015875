import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type GqlActivateTestKitInput = {
  activationCode: Scalars['String'];
  dateOfBirth: Scalars['Date'];
};

export type GqlAddress = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type GqlAddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  country_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  province_code?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type GqlApptNotes = {
  actions?: Maybe<GqlApptNotesActions>;
  agenda?: Maybe<GqlApptNotesAgenda>;
  answer?: Maybe<GqlApptNotesAnswer>;
  audit?: Maybe<GqlApptNotesAudit>;
  nurse?: Maybe<GqlApptNotesNurse>;
};

export type GqlApptNotesActions = {
  notes?: Maybe<Scalars['String']>;
  onContraception?: Maybe<Scalars['String']>;
  recommendGpAppt?: Maybe<Scalars['Boolean']>;
  showHprDownload?: Maybe<Scalars['Boolean']>;
};

export type GqlApptNotesActionsInput = {
  notes?: InputMaybe<Scalars['String']>;
  onContraception?: InputMaybe<Scalars['String']>;
  recommendGpAppt?: InputMaybe<Scalars['Boolean']>;
  showHprDownload?: InputMaybe<Scalars['Boolean']>;
};

export type GqlApptNotesAgenda = {
  communicatedNoMedicalHistory?: Maybe<Scalars['Boolean']>;
  confirmedTeleconferencing?: Maybe<Scalars['Boolean']>;
  consent?: Maybe<Scalars['Boolean']>;
  introduced?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['Boolean']>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type GqlApptNotesAgendaInput = {
  communicatedNoMedicalHistory?: InputMaybe<Scalars['Boolean']>;
  confirmedTeleconferencing?: InputMaybe<Scalars['Boolean']>;
  consent?: InputMaybe<Scalars['Boolean']>;
  introduced?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['Boolean']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};

export type GqlApptNotesAnswer = {
  messageDelivered?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type GqlApptNotesAnswerInput = {
  messageDelivered?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type GqlApptNotesAudit = {
  informationCorrect?: Maybe<Scalars['String']>;
  lowerByMedicalHistory?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  recentContraception?: Maybe<Scalars['String']>;
};

export type GqlApptNotesAuditInput = {
  informationCorrect?: InputMaybe<Scalars['String']>;
  lowerByMedicalHistory?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  recentContraception?: InputMaybe<Scalars['String']>;
};

export type GqlApptNotesInput = {
  actions?: InputMaybe<GqlApptNotesActionsInput>;
  agenda?: InputMaybe<GqlApptNotesAgendaInput>;
  answer?: InputMaybe<GqlApptNotesAnswerInput>;
  audit?: InputMaybe<GqlApptNotesAuditInput>;
  nurse?: InputMaybe<GqlApptNotesNurseInput>;
};

export type GqlApptNotesNurse = {
  concernAboutClient?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  technicalIssuesDuringAppt?: Maybe<Scalars['String']>;
};

export type GqlApptNotesNurseInput = {
  concernAboutClient?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  technicalIssuesDuringAppt?: InputMaybe<Scalars['String']>;
};

export type GqlAuditLog = {
  changeSet?: Maybe<Scalars['JSON']>;
  changedBy: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  entityId: Scalars['String'];
  entityName: Scalars['String'];
};

export type GqlAuditLogChangeSet = {
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  propertyName: Scalars['String'];
};

export type GqlAuditLogInput = {
  entityName: Scalars['String'];
  userId: Scalars['String'];
};

export type GqlAuditLogs = {
  auditLogs: Array<GqlAuditLog>;
};

export type GqlByIdInput = {
  id: Scalars['ID'];
};

export type GqlCheckActivationCodeInput = {
  activationCode: Scalars['String'];
};

export type GqlCheckActivationCodeResult = {
  isValid: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCreateNoteInput = {
  note: Scalars['String'];
  userId: Scalars['String'];
};

export type GqlCreateOrUpdateQuestionnaireInput = {
  details?: InputMaybe<Array<GqlQuestionnaireDetailsInput>>;
  testKitId: Scalars['ID'];
};

export type GqlCreateTestKitInput = {
  userId: Scalars['String'];
};

export type GqlFilledApptNotesActionsInput = {
  notes?: InputMaybe<Scalars['String']>;
  onContraception: Scalars['String'];
  recommendGpAppt: Scalars['Boolean'];
  showHprDownload: Scalars['Boolean'];
};

export type GqlFilledApptNotesAgendaInput = {
  communicatedNoMedicalHistory: Scalars['Boolean'];
  confirmedTeleconferencing: Scalars['Boolean'];
  consent: Scalars['Boolean'];
  introduced: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  purpose: Scalars['Boolean'];
  verify: Scalars['Boolean'];
};

export type GqlFilledApptNotesAnswerInput = {
  messageDelivered: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
};

export type GqlFilledApptNotesAuditInput = {
  informationCorrect: Scalars['String'];
  lowerByMedicalHistory: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  recentContraception: Scalars['String'];
};

export type GqlFilledApptNotesInput = {
  actions: GqlFilledApptNotesActionsInput;
  agenda: GqlFilledApptNotesAgendaInput;
  answer: GqlFilledApptNotesAnswerInput;
  audit: GqlFilledApptNotesAuditInput;
  nurse: GqlFilledApptNotesNurseInput;
};

export type GqlFilledApptNotesNurseInput = {
  concernAboutClient: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  technicalIssuesDuringAppt: Scalars['String'];
};

export type GqlFinishApptNotesInput = {
  apptNotes: GqlFilledApptNotesInput;
  id: Scalars['ID'];
  userId: Scalars['String'];
};

export type GqlGetProfileFromS3Input = {
  id: Scalars['ID'];
};

export type GqlMarkHasHadAppointmentBookedInput = {
  apptBookedAnswer?: InputMaybe<Scalars['String']>;
  testKitId: Scalars['ID'];
};

export type GqlMarkHasPreparedForResultsInput = {
  testKitId: Scalars['ID'];
};

export type GqlMarkHasViewedResultInput = {
  testKitId: Scalars['ID'];
};

export type GqlMarkSampleAsCollectedInput = {
  testKitId: Scalars['ID'];
};

export type GqlMutation = {
  activateTestKit: GqlTestKit;
  create: GqlTestKit;
  createOrUpdateQuestionnaire: GqlQuestionnaire;
  createPatientNote: GqlNote;
  finishApptNotes: GqlTestKit;
  getProfileFromS3: GqlTestKitProfile;
  markHasHadAppointmentBooked: GqlTestKit;
  markHasPreparedForResults: GqlTestKit;
  markHasViewedResult: GqlTestKit;
  markSampleAsCollected: GqlTestKit;
  regenerateReport: GqlTestKit;
  releaseTestKitResults: GqlTestKit;
  root?: Maybe<Scalars['String']>;
  signUp: GqlUser;
  updateApptNotes: GqlTestKit;
  updateTestKit: GqlTestKit;
  updateUser: GqlUser;
};


export type GqlMutationActivateTestKitArgs = {
  input: GqlActivateTestKitInput;
};


export type GqlMutationCreateArgs = {
  input: GqlCreateTestKitInput;
};


export type GqlMutationCreateOrUpdateQuestionnaireArgs = {
  input: GqlCreateOrUpdateQuestionnaireInput;
};


export type GqlMutationCreatePatientNoteArgs = {
  input: GqlCreateNoteInput;
};


export type GqlMutationFinishApptNotesArgs = {
  input: GqlFinishApptNotesInput;
};


export type GqlMutationGetProfileFromS3Args = {
  input: GqlGetProfileFromS3Input;
};


export type GqlMutationMarkHasHadAppointmentBookedArgs = {
  input: GqlMarkHasHadAppointmentBookedInput;
};


export type GqlMutationMarkHasPreparedForResultsArgs = {
  input: GqlMarkHasPreparedForResultsInput;
};


export type GqlMutationMarkHasViewedResultArgs = {
  input: GqlMarkHasViewedResultInput;
};


export type GqlMutationMarkSampleAsCollectedArgs = {
  input: GqlMarkSampleAsCollectedInput;
};


export type GqlMutationRegenerateReportArgs = {
  input: GqlRegenerateReportInput;
};


export type GqlMutationReleaseTestKitResultsArgs = {
  input: GqlReleaseTestKitResultsInput;
};


export type GqlMutationSignUpArgs = {
  input: GqlSignUpInput;
};


export type GqlMutationUpdateApptNotesArgs = {
  input: GqlUpdateApptNotesInput;
};


export type GqlMutationUpdateTestKitArgs = {
  input: GqlUpdateTestKitInput;
};


export type GqlMutationUpdateUserArgs = {
  input: GqlUpdateUserInput;
};

export type GqlNote = {
  addedBy: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  note: Scalars['String'];
  updatedAt: Scalars['Date'];
  userId: Scalars['String'];
};

export type GqlNoteInput = {
  userId: Scalars['String'];
};

export type GqlPatient = {
  id: Scalars['ID'];
  testKits: Array<GqlTestKit>;
  user: GqlUser;
};

export type GqlPatientNotes = {
  notes: Array<GqlNote>;
};

export type GqlPatientsFilter =
  | 'ALL'
  | 'AWAITING_RESULTS'
  | 'READY_TO_RELEASE';

export type GqlPatientsInput = {
  filter?: InputMaybe<GqlPatientsFilter>;
  search?: InputMaybe<Scalars['String']>;
};

export type GqlPreSignedLink = {
  preSignedLink: Scalars['String'];
};

export type GqlProductItem = {
  kitId: Scalars['String'];
  title: Scalars['String'];
};

export type GqlQuery = {
  auditLogs: GqlAuditLogs;
  checkActivationCode: GqlCheckActivationCodeResult;
  findByID: GqlTestKit;
  getReportLink: GqlPreSignedLink;
  patientByCurrentUser: GqlPatient;
  patientById: GqlPatient;
  patientNotes: GqlPatientNotes;
  patients: Array<GqlPatient>;
  questionnaireByTestKitId?: Maybe<GqlQuestionnaire>;
  root?: Maybe<Scalars['String']>;
  testKits: GqlTestKitsResult;
  userById: GqlUser;
  userByToken: GqlUser;
  userProfile: GqlUser;
};


export type GqlQueryAuditLogsArgs = {
  input: InputMaybe<GqlAuditLogInput>;
};


export type GqlQueryCheckActivationCodeArgs = {
  input: GqlCheckActivationCodeInput;
};


export type GqlQueryFindByIdArgs = {
  input: InputMaybe<GqlByIdInput>;
};


export type GqlQueryGetReportLinkArgs = {
  input: GqlReportLinkInput;
};


export type GqlQueryPatientByIdArgs = {
  input: InputMaybe<GqlByIdInput>;
};


export type GqlQueryPatientNotesArgs = {
  input: InputMaybe<GqlNoteInput>;
};


export type GqlQueryPatientsArgs = {
  input: InputMaybe<GqlPatientsInput>;
};


export type GqlQueryQuestionnaireByTestKitIdArgs = {
  input: InputMaybe<GqlByIdInput>;
};


export type GqlQueryTestKitsArgs = {
  input: InputMaybe<GqlTestKitInput>;
};


export type GqlQueryUserByIdArgs = {
  input: InputMaybe<GqlByIdInput>;
};

export type GqlQuestionnaire = {
  details: Array<GqlQuestionnaireDetailsField>;
  id: Scalars['ID'];
  testKitId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type GqlQuestionnaireDetailsField = {
  comment?: Maybe<Scalars['String']>;
  key: GqlQuestionnaireDetailsFieldKey;
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type GqlQuestionnaireDetailsFieldKey =
  | 'ADDITIONAL_INFO'
  | 'ADDITIONAL_NOTE'
  | 'ALCOHOL'
  | 'ALCOHOL_NUMBER'
  | 'CONTRACEPTION'
  | 'CONTRACEPTION_IUD'
  | 'CONTRACEPTION_PILL'
  | 'CONTRACEPTION_TYPE'
  | 'ETHNICITY'
  | 'EXISTING_DIAGNOSES'
  | 'EXISTING_DIAGNOSES_INFO'
  | 'E_CIGARETTE'
  | 'HEIGHT'
  | 'HOW_LONG_TRYING_PREGNANT'
  | 'INFO'
  | 'MEDICATION'
  | 'MEDICATION_INFO'
  | 'MISCARRIAGE'
  | 'MISCARRIAGE_INFO'
  | 'PERIOD_FREQUENCY'
  | 'PERIOD_PATCH'
  | 'PERIOD_PILL'
  | 'PERIOD_REGULAR'
  | 'PERIOD_RING'
  | 'PREGNANCY'
  | 'PROFILE_HISTORY'
  | 'RECENT_HORMONAL_CONTRACEPTION'
  | 'SMOKING'
  | 'SMOKING_NUMBER'
  | 'TEST_PLANING'
  | 'TEST_REASON'
  | 'TEST_RELATIONSHIP_STATUS'
  | 'WEIGHT';

export type GqlQuestionnaireDetailsInput = {
  comment?: InputMaybe<Scalars['String']>;
  key: GqlQuestionnaireDetailsFieldKey;
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type GqlRegenerateReportInput = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};

export type GqlReleaseTestKitResultsInput = {
  id: Scalars['ID'];
};

export type GqlReportLinkInput = {
  reportType: GqlReportType;
  testKitId: Scalars['ID'];
};

export type GqlReportType =
  | 'APPOINTMENT_NOTE'
  | 'EGG_COUNT_REPORT'
  | 'HEALTH_PRACTITIONER_REPORT';

export type GqlResult = {
  abbreviation: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  report?: Maybe<GqlResultReport>;
  result: Scalars['Float'];
  units: Scalars['String'];
};

export type GqlResultReferenceRange =
  | 'HIGH'
  | 'IN_RANGE'
  | 'LOW'
  | 'PERCENTILE_2_5';

export type GqlResultReferenceRangeValues = {
  HIGH: Scalars['Float'];
  LOW: Scalars['Float'];
  PERCENTILE_2_5: Scalars['Float'];
};

export type GqlResultReport = {
  ageGroup: Scalars['String'];
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  range: GqlResultReferenceRangeValues;
  referenceRange: GqlResultReferenceRange;
};

export type GqlResultsStatus =
  | 'NOT_RECEIVED'
  | 'RECEIVED_NOT_RELEASED'
  | 'RECEIVED_RELEASED';

export type GqlSignUpInput = {
  address: GqlAddressInput;
  agreeToTermsAndConditions: Scalars['Boolean'];
  cfTurnstileResponse: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type GqlTestKit = {
  accessionNumber?: Maybe<Scalars['String']>;
  activationCode?: Maybe<Scalars['String']>;
  apptBookedAnswer?: Maybe<Scalars['String']>;
  apptNotes?: Maybe<GqlApptNotes>;
  apptNotesReportFile?: Maybe<GqlTestKitReportFile>;
  clinicalNotes?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  dateResultsReceived?: Maybe<Scalars['Date']>;
  dateSampleCollected?: Maybe<Scalars['Date']>;
  hasPreparedForResults?: Maybe<Scalars['Boolean']>;
  hasViewedResult?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isSampleCollected?: Maybe<Scalars['Boolean']>;
  nutripathCreatedAt?: Maybe<Scalars['Date']>;
  nutripathUpdatedAt?: Maybe<Scalars['Date']>;
  product?: Maybe<GqlProductItem>;
  questionnaire?: Maybe<GqlQuestionnaire>;
  results?: Maybe<Array<GqlResult>>;
  resultsStatus: GqlResultsStatus;
  shippingUrl?: Maybe<Scalars['String']>;
  status?: Maybe<GqlTestKitStatus>;
  updatedAt: Scalars['Date'];
  userId?: Maybe<Scalars['String']>;
};

export type GqlTestKitInput = {
  activationCode?: InputMaybe<Scalars['String']>;
  orderDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<GqlTestKitStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type GqlTestKitProfile = {
  items?: Maybe<Array<Maybe<GqlTestKitProfileItem>>>;
};

export type GqlTestKitProfileItem = {
  comment?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type GqlTestKitReportFile = {
  reportType?: Maybe<GqlReportType>;
  s3Path?: Maybe<Scalars['String']>;
};

export type GqlTestKitStatus =
  | 'ACTIVATED'
  | 'CANCELLED'
  | 'CLOSED'
  | 'DRAFT'
  | 'ERROR'
  | 'LABORATORY_PROCESSING'
  | 'OPEN'
  | 'REPICK'
  | 'SPECIMEN_RECEIVED'
  | 'SPECIMEN_REJECTED'
  | 'SUBMITTED';

export type GqlTestKitsResult = {
  testKits: Array<GqlTestKit>;
};

export type GqlUpdateApptNotesInput = {
  apptNotes: GqlApptNotesInput;
  id: Scalars['ID'];
  userId: Scalars['String'];
};

export type GqlUpdateTestKitInput = {
  dateSampleCollected?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<GqlTestKitStatus>;
  testKitId: Scalars['ID'];
};

export type GqlUpdateUserInput = {
  address?: InputMaybe<GqlAddressInput>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type GqlUser = {
  address?: Maybe<GqlAddress>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firebaseUid: Scalars['ID'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<GqlUserRole>;
};

export type GqlUserRole =
  | 'ADMIN'
  | 'NURSE'
  | 'PATIENT';

export type GqlUserSortField =
  | 'dateAdded';

export type GqlActivatePageUserFragmentFragment = { id: string, dateOfBirth?: any | null };

export type GqlActivateTestKitMutationVariables = Exact<{
  input: GqlActivateTestKitInput;
}>;


export type GqlActivateTestKitMutation = { activateTestKit: { id: string } };

export type GqlActivatePageGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlActivatePageGetUserQuery = { userProfile: { id: string, dateOfBirth?: any | null } };

export type GqlActivateActivationCodeCheckQueryVariables = Exact<{
  input: GqlCheckActivationCodeInput;
}>;


export type GqlActivateActivationCodeCheckQuery = { checkActivationCode: { isValid: boolean, message?: string | null } };

export type GqlSignUpMutationVariables = Exact<{
  input: GqlSignUpInput;
}>;


export type GqlSignUpMutation = { signUp: { email: string, firstName: string, lastName: string } };

export type GqlHasHadAppointmentBookedButtonAppointmentBookedMutationVariables = Exact<{
  input: GqlMarkHasHadAppointmentBookedInput;
}>;


export type GqlHasHadAppointmentBookedButtonAppointmentBookedMutation = { markHasHadAppointmentBooked: { id: string, apptBookedAnswer?: string | null } };

export type GqlCollectSampleButtonCollectSampleMutationVariables = Exact<{
  input: GqlMarkSampleAsCollectedInput;
}>;


export type GqlCollectSampleButtonCollectSampleMutation = { markSampleAsCollected: { id: string } };

export type GqlPrepareForResultsMutationVariables = Exact<{
  input: GqlMarkHasPreparedForResultsInput;
}>;


export type GqlPrepareForResultsMutation = { markHasPreparedForResults: { id: string } };

export type GqlTestKitSummaryTestKitFragmentFragment = { id: string, status?: GqlTestKitStatus | null, isSampleCollected?: boolean | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, hasPreparedForResults?: boolean | null, hasViewedResult?: boolean | null, dateResultsReceived?: any | null, apptBookedAnswer?: string | null, accessionNumber?: string | null, activationCode?: string | null, results?: Array<{ abbreviation: string, units: string, name: string, result: number, report?: { ageGroup: string, notes?: Array<string | null> | null, referenceRange: GqlResultReferenceRange, range: { PERCENTILE_2_5: number, LOW: number, HIGH: number } } | null }> | null, questionnaire?: { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> } | null };

export type GqlTestKitSummaryGetTestKitsQueryVariables = Exact<{
  testKitInput: InputMaybe<GqlTestKitInput>;
}>;


export type GqlTestKitSummaryGetTestKitsQuery = { testKits: { testKits: Array<{ id: string, status?: GqlTestKitStatus | null, isSampleCollected?: boolean | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, hasPreparedForResults?: boolean | null, hasViewedResult?: boolean | null, dateResultsReceived?: any | null, apptBookedAnswer?: string | null, accessionNumber?: string | null, activationCode?: string | null, results?: Array<{ abbreviation: string, units: string, name: string, result: number, report?: { ageGroup: string, notes?: Array<string | null> | null, referenceRange: GqlResultReferenceRange, range: { PERCENTILE_2_5: number, LOW: number, HIGH: number } } | null }> | null, questionnaire?: { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> } | null }> } };

export type GqlViewedResultButtonViewedResultMutationVariables = Exact<{
  input: GqlMarkHasViewedResultInput;
}>;


export type GqlViewedResultButtonViewedResultMutation = { markHasViewedResult: { id: string } };

export type GqlDashboardPageUserFragmentFragment = { id: string, firstName: string, lastName: string };

export type GqlDashboardTestKitFragmentFragment = { id: string, status?: GqlTestKitStatus | null, isSampleCollected?: boolean | null, dateSampleCollected?: any | null, dateResultsReceived?: any | null, resultsStatus: GqlResultsStatus, results?: Array<{ report?: { referenceRange: GqlResultReferenceRange } | null }> | null };

export type GqlDashboardPageGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlDashboardPageGetUserQuery = { userProfile: { id: string, firstName: string, lastName: string } };

export type GqlDashboardGetTestKitsQueryVariables = Exact<{
  testKitInput: InputMaybe<GqlTestKitInput>;
}>;


export type GqlDashboardGetTestKitsQuery = { testKits: { testKits: Array<{ id: string, status?: GqlTestKitStatus | null, isSampleCollected?: boolean | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, hasPreparedForResults?: boolean | null, hasViewedResult?: boolean | null, dateResultsReceived?: any | null, apptBookedAnswer?: string | null, accessionNumber?: string | null, activationCode?: string | null, results?: Array<{ abbreviation: string, units: string, name: string, result: number, report?: { ageGroup: string, notes?: Array<string | null> | null, referenceRange: GqlResultReferenceRange, range: { PERCENTILE_2_5: number, LOW: number, HIGH: number } } | null }> | null, questionnaire?: { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> } | null }> } };

export type GqlQuizPageTestKitFragmentFragment = { id: string, status?: GqlTestKitStatus | null };

export type GqlQuizPageFindByIdQueryVariables = Exact<{
  input: InputMaybe<GqlByIdInput>;
}>;


export type GqlQuizPageFindByIdQuery = { findByID: { id: string, status?: GqlTestKitStatus | null } };

export type GqlResultsPageTestKitFragmentFragment = { id: string, userId?: string | null, activationCode?: string | null, dateSampleCollected?: any | null, status?: GqlTestKitStatus | null, resultsStatus: GqlResultsStatus, accessionNumber?: string | null, product?: { title: string } | null, questionnaire?: { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> } | null };

export type GqlGetResultsPageQueryVariables = Exact<{
  input: InputMaybe<GqlTestKitInput>;
}>;


export type GqlGetResultsPageQuery = { testKits: { testKits: Array<{ id: string, userId?: string | null, activationCode?: string | null, dateSampleCollected?: any | null, status?: GqlTestKitStatus | null, resultsStatus: GqlResultsStatus, accessionNumber?: string | null, product?: { title: string } | null, questionnaire?: { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> } | null }> } };

export type GqlSettingsSummaryItemUpdateUserMutationVariables = Exact<{
  input: GqlUpdateUserInput;
}>;


export type GqlSettingsSummaryItemUpdateUserMutation = { updateUser: { id: string } };

export type GqlSettingsSummaryUserFragmentFragment = { id: string, firstName: string, lastName: string, email: string, phone?: string | null, address?: { address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, country?: string | null, zip?: string | null, phone?: string | null, province_code?: string | null, country_code?: string | null, country_name?: string | null } | null };

export type GqlSettingsSummaryGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlSettingsSummaryGetUserQuery = { userProfile: { id: string, firstName: string, lastName: string, email: string, phone?: string | null, address?: { address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, country?: string | null, zip?: string | null, phone?: string | null, province_code?: string | null, country_code?: string | null, country_name?: string | null } | null } };

export type GqlTestKitHistoryTestKitFragmentFragment = { id: string, activationCode?: string | null, status?: GqlTestKitStatus | null, shippingUrl?: string | null };

export type GqlGetTestKitHistoryQueryVariables = Exact<{
  input: InputMaybe<GqlTestKitInput>;
}>;


export type GqlGetTestKitHistoryQuery = { testKits: { testKits: Array<{ id: string, activationCode?: string | null, status?: GqlTestKitStatus | null, shippingUrl?: string | null }> } };

export type GqlUseCurrentUserUserFragmentFragment = { id: string, role?: GqlUserRole | null };

export type GqlUseCurrentUserGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlUseCurrentUserGetUserQuery = { userProfile: { id: string, role?: GqlUserRole | null } };

export type GqlAppNotePageUserFragmentFragment = { id: string, user: { firstName: string, lastName: string, email: string, dateOfBirth?: any | null, phone?: string | null, address?: { address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, country?: string | null, zip?: string | null, province_code?: string | null } | null }, testKits: Array<{ id: string, userId?: string | null, status?: GqlTestKitStatus | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, createdAt: any, apptNotes?: { agenda?: { introduced?: boolean | null, verify?: boolean | null, purpose?: boolean | null, consent?: boolean | null, confirmedTeleconferencing?: boolean | null, communicatedNoMedicalHistory?: boolean | null, notes?: string | null } | null, audit?: { informationCorrect?: string | null, recentContraception?: string | null, lowerByMedicalHistory?: string | null, notes?: string | null } | null, answer?: { messageDelivered?: boolean | null, notes?: string | null } | null, actions?: { onContraception?: string | null, recommendGpAppt?: boolean | null, showHprDownload?: boolean | null, notes?: string | null } | null, nurse?: { concernAboutClient?: string | null, technicalIssuesDuringAppt?: string | null, notes?: string | null } | null } | null, product?: { title: string } | null, results?: Array<{ report?: { referenceRange: GqlResultReferenceRange } | null }> | null }> };

export type GqlAppNotePageGetPatientQueryVariables = Exact<{
  input: InputMaybe<GqlByIdInput>;
}>;


export type GqlAppNotePageGetPatientQuery = { patientById: { id: string, user: { firstName: string, lastName: string, email: string, dateOfBirth?: any | null, phone?: string | null, address?: { address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, country?: string | null, zip?: string | null, province_code?: string | null } | null }, testKits: Array<{ id: string, userId?: string | null, status?: GqlTestKitStatus | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, createdAt: any, apptNotes?: { agenda?: { introduced?: boolean | null, verify?: boolean | null, purpose?: boolean | null, consent?: boolean | null, confirmedTeleconferencing?: boolean | null, communicatedNoMedicalHistory?: boolean | null, notes?: string | null } | null, audit?: { informationCorrect?: string | null, recentContraception?: string | null, lowerByMedicalHistory?: string | null, notes?: string | null } | null, answer?: { messageDelivered?: boolean | null, notes?: string | null } | null, actions?: { onContraception?: string | null, recommendGpAppt?: boolean | null, showHprDownload?: boolean | null, notes?: string | null } | null, nurse?: { concernAboutClient?: string | null, technicalIssuesDuringAppt?: string | null, notes?: string | null } | null } | null, product?: { title: string } | null, results?: Array<{ report?: { referenceRange: GqlResultReferenceRange } | null }> | null }> } };

export type GqlUpdateApptNotesMutationVariables = Exact<{
  input: GqlUpdateApptNotesInput;
}>;


export type GqlUpdateApptNotesMutation = { updateApptNotes: { id: string } };

export type GqlRegenerateReportMutationVariables = Exact<{
  input: GqlRegenerateReportInput;
}>;


export type GqlRegenerateReportMutation = { regenerateReport: { id: string } };

export type GqlFinishApptNotesMutationVariables = Exact<{
  input: GqlFinishApptNotesInput;
}>;


export type GqlFinishApptNotesMutation = { finishApptNotes: { id: string } };

export type GqlMeetingNotesFragmentFragment = { id: string, userId: string, note: string, addedBy: string, createdAt: any };

export type GqlCreatePatientMeetingNotesMutationVariables = Exact<{
  input: GqlCreateNoteInput;
}>;


export type GqlCreatePatientMeetingNotesMutation = { createPatientNote: { id: string, userId: string, note: string, addedBy: string, createdAt: any } };

export type GqlGetPatientMeetingNotesQueryVariables = Exact<{
  input: InputMaybe<GqlNoteInput>;
}>;


export type GqlGetPatientMeetingNotesQuery = { patientNotes: { notes: Array<{ id: string, userId: string, note: string, addedBy: string, createdAt: any }> } };

export type GqlPatientResultsButtonsReleaseResultsMutationVariables = Exact<{
  input: GqlReleaseTestKitResultsInput;
}>;


export type GqlPatientResultsButtonsReleaseResultsMutation = { releaseTestKitResults: { id: string, resultsStatus: GqlResultsStatus } };

export type GqlPatientsListComponentPatientFragmentFragment = { id: string, user: { firstName: string, lastName: string, email: string, dateOfBirth?: any | null, phone?: string | null } };

export type GqlPatientsListComponentGetPatientsQueryVariables = Exact<{
  PatientsInput: InputMaybe<GqlPatientsInput>;
}>;


export type GqlPatientsListComponentGetPatientsQuery = { patients: Array<{ id: string, user: { firstName: string, lastName: string, email: string, dateOfBirth?: any | null, phone?: string | null } }> };

export type GqlViewQuestionnareButtonGetProfileFromS3MutationVariables = Exact<{
  input: GqlGetProfileFromS3Input;
}>;


export type GqlViewQuestionnareButtonGetProfileFromS3Mutation = { getProfileFromS3: { items?: Array<{ key: string, value?: string | null, values?: Array<string> | null, comment?: string | null } | null> | null } };

export type GqlAuditLogsFragmentFragment = { entityName: string, changedBy: string, changeSet?: any | null, createdAt: any, comment?: string | null };

export type GqlPatientAuditHistoryPageUserFragmentFragment = { id: string, firstName: string, lastName: string, email: string };

export type GqlPatientAuditHistoryPageTestKitsFragmentFragment = { id: string, status?: GqlTestKitStatus | null, resultsStatus: GqlResultsStatus, accessionNumber?: string | null, nutripathUpdatedAt?: any | null, dateSampleCollected?: any | null, activationCode?: string | null };

export type GqlGetUserAuditLogsQueryVariables = Exact<{
  input: InputMaybe<GqlAuditLogInput>;
}>;


export type GqlGetUserAuditLogsQuery = { auditLogs: { auditLogs: Array<{ entityName: string, changedBy: string, changeSet?: any | null, createdAt: any, comment?: string | null }> } };

export type GqlGetUserTestKitsQueryVariables = Exact<{
  input: InputMaybe<GqlTestKitInput>;
}>;


export type GqlGetUserTestKitsQuery = { testKits: { testKits: Array<{ id: string, status?: GqlTestKitStatus | null, resultsStatus: GqlResultsStatus, accessionNumber?: string | null, nutripathUpdatedAt?: any | null, dateSampleCollected?: any | null, activationCode?: string | null }> } };

export type GqlPatientAuditHistoryPageGetUserQueryVariables = Exact<{
  input: InputMaybe<GqlByIdInput>;
}>;


export type GqlPatientAuditHistoryPageGetUserQuery = { userById: { id: string, firstName: string, lastName: string, email: string } };

export type GqlPatientProfilePageUserFragmentFragment = { id: string, user: { id: string, firstName: string, lastName: string, email: string, dateOfBirth?: any | null, phone?: string | null, address?: { address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, country?: string | null, zip?: string | null, province_code?: string | null } | null }, testKits: Array<{ id: string, userId?: string | null, accessionNumber?: string | null, activationCode?: string | null, status?: GqlTestKitStatus | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, createdAt: any, apptNotesReportFile?: { s3Path?: string | null } | null, apptNotes?: { agenda?: { introduced?: boolean | null, verify?: boolean | null, purpose?: boolean | null, consent?: boolean | null, confirmedTeleconferencing?: boolean | null, communicatedNoMedicalHistory?: boolean | null, notes?: string | null } | null, audit?: { informationCorrect?: string | null, recentContraception?: string | null, lowerByMedicalHistory?: string | null, notes?: string | null } | null, answer?: { messageDelivered?: boolean | null, notes?: string | null } | null, actions?: { onContraception?: string | null, recommendGpAppt?: boolean | null, showHprDownload?: boolean | null, notes?: string | null } | null, nurse?: { concernAboutClient?: string | null, technicalIssuesDuringAppt?: string | null, notes?: string | null } | null } | null, product?: { title: string } | null, results?: Array<{ report?: { referenceRange: GqlResultReferenceRange } | null }> | null }> };

export type GqlPatientProfilePageGetPatientQueryVariables = Exact<{
  input: InputMaybe<GqlByIdInput>;
}>;


export type GqlPatientProfilePageGetPatientQuery = { patientById: { id: string, user: { id: string, firstName: string, lastName: string, email: string, dateOfBirth?: any | null, phone?: string | null, address?: { address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, country?: string | null, zip?: string | null, province_code?: string | null } | null }, testKits: Array<{ id: string, userId?: string | null, accessionNumber?: string | null, activationCode?: string | null, status?: GqlTestKitStatus | null, dateSampleCollected?: any | null, resultsStatus: GqlResultsStatus, createdAt: any, apptNotesReportFile?: { s3Path?: string | null } | null, apptNotes?: { agenda?: { introduced?: boolean | null, verify?: boolean | null, purpose?: boolean | null, consent?: boolean | null, confirmedTeleconferencing?: boolean | null, communicatedNoMedicalHistory?: boolean | null, notes?: string | null } | null, audit?: { informationCorrect?: string | null, recentContraception?: string | null, lowerByMedicalHistory?: string | null, notes?: string | null } | null, answer?: { messageDelivered?: boolean | null, notes?: string | null } | null, actions?: { onContraception?: string | null, recommendGpAppt?: boolean | null, showHprDownload?: boolean | null, notes?: string | null } | null, nurse?: { concernAboutClient?: string | null, technicalIssuesDuringAppt?: string | null, notes?: string | null } | null } | null, product?: { title: string } | null, results?: Array<{ report?: { referenceRange: GqlResultReferenceRange } | null }> | null }> } };

export type GqlQuestionnairePageTestKitFragment = { id: string, userId?: string | null, accessionNumber?: string | null };

export type GqlQuestionnairePageGetTestKitByIdQueryVariables = Exact<{
  input: InputMaybe<GqlByIdInput>;
}>;


export type GqlQuestionnairePageGetTestKitByIdQuery = { findByID: { id: string, userId?: string | null, accessionNumber?: string | null } };

export type GqlQuizPageGetTestKitsQueryVariables = Exact<{
  input: InputMaybe<GqlTestKitInput>;
}>;


export type GqlQuizPageGetTestKitsQuery = { testKits: { testKits: Array<{ id: string, status?: GqlTestKitStatus | null }> } };

export type GqlQuestionnaireProviderQuestionnaireFragmentFragment = { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> };

export type GqlUpdateQuestionnaireMutationVariables = Exact<{
  input: GqlCreateOrUpdateQuestionnaireInput;
}>;


export type GqlUpdateQuestionnaireMutation = { createOrUpdateQuestionnaire: { id: string } };

export type GqlQuestionnaireProviderQuestionnaireByTestKitIdQueryVariables = Exact<{
  input: InputMaybe<GqlByIdInput>;
}>;


export type GqlQuestionnaireProviderQuestionnaireByTestKitIdQuery = { questionnaireByTestKitId?: { id: string, userId?: string | null, testKitId: string, details: Array<{ key: GqlQuestionnaireDetailsFieldKey, value?: string | null, values?: Array<string> | null, comment?: string | null }> } | null };

export type GqlDownloadReportQueryVariables = Exact<{
  input: GqlReportLinkInput;
}>;


export type GqlDownloadReportQuery = { getReportLink: { preSignedLink: string } };

export const ActivatePageUserFragmentFragmentDoc = gql`
    fragment ActivatePageUserFragment on User {
  id
  dateOfBirth
}
    `;
export const TestKitSummaryTestKitFragmentFragmentDoc = gql`
    fragment TestKitSummaryTestKitFragment on TestKit {
  id
  status
  isSampleCollected
  dateSampleCollected
  resultsStatus
  hasPreparedForResults
  hasViewedResult
  dateResultsReceived
  apptBookedAnswer
  accessionNumber
  activationCode
  results {
    abbreviation
    units
    name
    result
    report {
      ageGroup
      notes
      referenceRange
      range {
        PERCENTILE_2_5
        LOW
        HIGH
      }
    }
  }
  questionnaire {
    id
    userId
    testKitId
    details {
      key
      value
      values
      comment
    }
  }
}
    `;
export const DashboardPageUserFragmentFragmentDoc = gql`
    fragment DashboardPageUserFragment on User {
  id
  firstName
  lastName
}
    `;
export const DashboardTestKitFragmentFragmentDoc = gql`
    fragment DashboardTestKitFragment on TestKit {
  id
  status
  isSampleCollected
  dateSampleCollected
  dateResultsReceived
  resultsStatus
  results {
    report {
      referenceRange
    }
  }
}
    `;
export const QuizPageTestKitFragmentFragmentDoc = gql`
    fragment QuizPageTestKitFragment on TestKit {
  id
  status
}
    `;
export const ResultsPageTestKitFragmentFragmentDoc = gql`
    fragment ResultsPageTestKitFragment on TestKit {
  id
  userId
  activationCode
  dateSampleCollected
  status
  resultsStatus
  accessionNumber
  product {
    title
  }
  questionnaire {
    id
    userId
    testKitId
    details {
      key
      value
      values
      comment
    }
  }
}
    `;
export const SettingsSummaryUserFragmentFragmentDoc = gql`
    fragment SettingsSummaryUserFragment on User {
  id
  firstName
  lastName
  email
  phone
  address {
    address1
    address2
    city
    province
    country
    zip
    phone
    province_code
    country_code
    country_name
  }
}
    `;
export const TestKitHistoryTestKitFragmentFragmentDoc = gql`
    fragment TestKitHistoryTestKitFragment on TestKit {
  id
  activationCode
  status
  shippingUrl
}
    `;
export const UseCurrentUserUserFragmentFragmentDoc = gql`
    fragment useCurrentUserUserFragment on User {
  id
  role
}
    `;
export const AppNotePageUserFragmentFragmentDoc = gql`
    fragment AppNotePageUserFragment on Patient {
  id
  user {
    firstName
    lastName
    email
    dateOfBirth
    phone
    address {
      address1
      address2
      city
      province
      country
      zip
      province_code
    }
  }
  testKits {
    id
    apptNotes {
      agenda {
        introduced
        verify
        purpose
        consent
        confirmedTeleconferencing
        communicatedNoMedicalHistory
        notes
      }
      audit {
        informationCorrect
        recentContraception
        lowerByMedicalHistory
        notes
      }
      answer {
        messageDelivered
        notes
      }
      actions {
        onContraception
        recommendGpAppt
        showHprDownload
        notes
      }
      nurse {
        concernAboutClient
        technicalIssuesDuringAppt
        notes
      }
    }
    userId
    status
    dateSampleCollected
    product {
      title
    }
    resultsStatus
    results {
      report {
        referenceRange
      }
    }
    createdAt
  }
}
    `;
export const MeetingNotesFragmentFragmentDoc = gql`
    fragment MeetingNotesFragment on Note {
  id
  userId
  note
  addedBy
  createdAt
}
    `;
export const PatientsListComponentPatientFragmentFragmentDoc = gql`
    fragment PatientsListComponentPatientFragment on Patient {
  id
  user {
    firstName
    lastName
    email
    dateOfBirth
    phone
  }
}
    `;
export const AuditLogsFragmentFragmentDoc = gql`
    fragment AuditLogsFragment on AuditLog {
  entityName
  changedBy
  changeSet
  createdAt
  comment
}
    `;
export const PatientAuditHistoryPageUserFragmentFragmentDoc = gql`
    fragment PatientAuditHistoryPageUserFragment on User {
  id
  firstName
  lastName
  email
}
    `;
export const PatientAuditHistoryPageTestKitsFragmentFragmentDoc = gql`
    fragment PatientAuditHistoryPageTestKitsFragment on TestKit {
  id
  status
  resultsStatus
  accessionNumber
  nutripathUpdatedAt
  dateSampleCollected
  activationCode
}
    `;
export const PatientProfilePageUserFragmentFragmentDoc = gql`
    fragment PatientProfilePageUserFragment on Patient {
  id
  user {
    id
    firstName
    lastName
    email
    dateOfBirth
    phone
    address {
      address1
      address2
      city
      province
      country
      zip
      province_code
    }
  }
  testKits {
    id
    apptNotesReportFile {
      s3Path
    }
    apptNotes {
      agenda {
        introduced
        verify
        purpose
        consent
        confirmedTeleconferencing
        communicatedNoMedicalHistory
        notes
      }
      audit {
        informationCorrect
        recentContraception
        lowerByMedicalHistory
        notes
      }
      answer {
        messageDelivered
        notes
      }
      actions {
        onContraception
        recommendGpAppt
        showHprDownload
        notes
      }
      nurse {
        concernAboutClient
        technicalIssuesDuringAppt
        notes
      }
    }
    userId
    accessionNumber
    activationCode
    status
    dateSampleCollected
    product {
      title
    }
    resultsStatus
    results {
      report {
        referenceRange
      }
    }
    createdAt
  }
}
    `;
export const QuestionnairePageTestKitFragmentDoc = gql`
    fragment QuestionnairePageTestKit on TestKit {
  id
  userId
  accessionNumber
}
    `;
export const QuestionnaireProviderQuestionnaireFragmentFragmentDoc = gql`
    fragment QuestionnaireProviderQuestionnaireFragment on Questionnaire {
  id
  userId
  testKitId
  details {
    key
    value
    values
    comment
  }
}
    `;
export const ActivateTestKitDocument = gql`
    mutation ActivateTestKit($input: ActivateTestKitInput!) {
  activateTestKit(input: $input) {
    id
  }
}
    `;
export type GqlActivateTestKitMutationFn = Apollo.MutationFunction<GqlActivateTestKitMutation, GqlActivateTestKitMutationVariables>;

/**
 * __useActivateTestKitMutation__
 *
 * To run a mutation, you first call `useActivateTestKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTestKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTestKitMutation, { data, loading, error }] = useActivateTestKitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateTestKitMutation(baseOptions?: Apollo.MutationHookOptions<GqlActivateTestKitMutation, GqlActivateTestKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlActivateTestKitMutation, GqlActivateTestKitMutationVariables>(ActivateTestKitDocument, options);
      }
export type ActivateTestKitMutationHookResult = ReturnType<typeof useActivateTestKitMutation>;
export type ActivateTestKitMutationResult = Apollo.MutationResult<GqlActivateTestKitMutation>;
export type ActivateTestKitMutationOptions = Apollo.BaseMutationOptions<GqlActivateTestKitMutation, GqlActivateTestKitMutationVariables>;
export const ActivatePageGetUserDocument = gql`
    query ActivatePageGetUser {
  userProfile {
    ...ActivatePageUserFragment
  }
}
    ${ActivatePageUserFragmentFragmentDoc}`;

/**
 * __useActivatePageGetUserQuery__
 *
 * To run a query within a React component, call `useActivatePageGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivatePageGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivatePageGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivatePageGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GqlActivatePageGetUserQuery, GqlActivatePageGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlActivatePageGetUserQuery, GqlActivatePageGetUserQueryVariables>(ActivatePageGetUserDocument, options);
      }
export function useActivatePageGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlActivatePageGetUserQuery, GqlActivatePageGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlActivatePageGetUserQuery, GqlActivatePageGetUserQueryVariables>(ActivatePageGetUserDocument, options);
        }
export type ActivatePageGetUserQueryHookResult = ReturnType<typeof useActivatePageGetUserQuery>;
export type ActivatePageGetUserLazyQueryHookResult = ReturnType<typeof useActivatePageGetUserLazyQuery>;
export type ActivatePageGetUserQueryResult = Apollo.QueryResult<GqlActivatePageGetUserQuery, GqlActivatePageGetUserQueryVariables>;
export const ActivateActivationCodeCheckDocument = gql`
    query ActivateActivationCodeCheck($input: CheckActivationCodeInput!) {
  checkActivationCode(input: $input) {
    isValid
    message
  }
}
    `;

/**
 * __useActivateActivationCodeCheckQuery__
 *
 * To run a query within a React component, call `useActivateActivationCodeCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivateActivationCodeCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivateActivationCodeCheckQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateActivationCodeCheckQuery(baseOptions: Apollo.QueryHookOptions<GqlActivateActivationCodeCheckQuery, GqlActivateActivationCodeCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlActivateActivationCodeCheckQuery, GqlActivateActivationCodeCheckQueryVariables>(ActivateActivationCodeCheckDocument, options);
      }
export function useActivateActivationCodeCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlActivateActivationCodeCheckQuery, GqlActivateActivationCodeCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlActivateActivationCodeCheckQuery, GqlActivateActivationCodeCheckQueryVariables>(ActivateActivationCodeCheckDocument, options);
        }
export type ActivateActivationCodeCheckQueryHookResult = ReturnType<typeof useActivateActivationCodeCheckQuery>;
export type ActivateActivationCodeCheckLazyQueryHookResult = ReturnType<typeof useActivateActivationCodeCheckLazyQuery>;
export type ActivateActivationCodeCheckQueryResult = Apollo.QueryResult<GqlActivateActivationCodeCheckQuery, GqlActivateActivationCodeCheckQueryVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    email
    firstName
    lastName
  }
}
    `;
export type GqlSignUpMutationFn = Apollo.MutationFunction<GqlSignUpMutation, GqlSignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<GqlSignUpMutation, GqlSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSignUpMutation, GqlSignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<GqlSignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<GqlSignUpMutation, GqlSignUpMutationVariables>;
export const HasHadAppointmentBookedButtonAppointmentBookedDocument = gql`
    mutation HasHadAppointmentBookedButtonAppointmentBooked($input: MarkHasHadAppointmentBookedInput!) {
  markHasHadAppointmentBooked(input: $input) {
    id
    apptBookedAnswer
  }
}
    `;
export type GqlHasHadAppointmentBookedButtonAppointmentBookedMutationFn = Apollo.MutationFunction<GqlHasHadAppointmentBookedButtonAppointmentBookedMutation, GqlHasHadAppointmentBookedButtonAppointmentBookedMutationVariables>;

/**
 * __useHasHadAppointmentBookedButtonAppointmentBookedMutation__
 *
 * To run a mutation, you first call `useHasHadAppointmentBookedButtonAppointmentBookedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHasHadAppointmentBookedButtonAppointmentBookedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hasHadAppointmentBookedButtonAppointmentBookedMutation, { data, loading, error }] = useHasHadAppointmentBookedButtonAppointmentBookedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasHadAppointmentBookedButtonAppointmentBookedMutation(baseOptions?: Apollo.MutationHookOptions<GqlHasHadAppointmentBookedButtonAppointmentBookedMutation, GqlHasHadAppointmentBookedButtonAppointmentBookedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlHasHadAppointmentBookedButtonAppointmentBookedMutation, GqlHasHadAppointmentBookedButtonAppointmentBookedMutationVariables>(HasHadAppointmentBookedButtonAppointmentBookedDocument, options);
      }
export type HasHadAppointmentBookedButtonAppointmentBookedMutationHookResult = ReturnType<typeof useHasHadAppointmentBookedButtonAppointmentBookedMutation>;
export type HasHadAppointmentBookedButtonAppointmentBookedMutationResult = Apollo.MutationResult<GqlHasHadAppointmentBookedButtonAppointmentBookedMutation>;
export type HasHadAppointmentBookedButtonAppointmentBookedMutationOptions = Apollo.BaseMutationOptions<GqlHasHadAppointmentBookedButtonAppointmentBookedMutation, GqlHasHadAppointmentBookedButtonAppointmentBookedMutationVariables>;
export const CollectSampleButtonCollectSampleDocument = gql`
    mutation CollectSampleButtonCollectSample($input: MarkSampleAsCollectedInput!) {
  markSampleAsCollected(input: $input) {
    id
  }
}
    `;
export type GqlCollectSampleButtonCollectSampleMutationFn = Apollo.MutationFunction<GqlCollectSampleButtonCollectSampleMutation, GqlCollectSampleButtonCollectSampleMutationVariables>;

/**
 * __useCollectSampleButtonCollectSampleMutation__
 *
 * To run a mutation, you first call `useCollectSampleButtonCollectSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectSampleButtonCollectSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectSampleButtonCollectSampleMutation, { data, loading, error }] = useCollectSampleButtonCollectSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectSampleButtonCollectSampleMutation(baseOptions?: Apollo.MutationHookOptions<GqlCollectSampleButtonCollectSampleMutation, GqlCollectSampleButtonCollectSampleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlCollectSampleButtonCollectSampleMutation, GqlCollectSampleButtonCollectSampleMutationVariables>(CollectSampleButtonCollectSampleDocument, options);
      }
export type CollectSampleButtonCollectSampleMutationHookResult = ReturnType<typeof useCollectSampleButtonCollectSampleMutation>;
export type CollectSampleButtonCollectSampleMutationResult = Apollo.MutationResult<GqlCollectSampleButtonCollectSampleMutation>;
export type CollectSampleButtonCollectSampleMutationOptions = Apollo.BaseMutationOptions<GqlCollectSampleButtonCollectSampleMutation, GqlCollectSampleButtonCollectSampleMutationVariables>;
export const PrepareForResultsDocument = gql`
    mutation PrepareForResults($input: MarkHasPreparedForResultsInput!) {
  markHasPreparedForResults(input: $input) {
    id
  }
}
    `;
export type GqlPrepareForResultsMutationFn = Apollo.MutationFunction<GqlPrepareForResultsMutation, GqlPrepareForResultsMutationVariables>;

/**
 * __usePrepareForResultsMutation__
 *
 * To run a mutation, you first call `usePrepareForResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareForResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareForResultsMutation, { data, loading, error }] = usePrepareForResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareForResultsMutation(baseOptions?: Apollo.MutationHookOptions<GqlPrepareForResultsMutation, GqlPrepareForResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPrepareForResultsMutation, GqlPrepareForResultsMutationVariables>(PrepareForResultsDocument, options);
      }
export type PrepareForResultsMutationHookResult = ReturnType<typeof usePrepareForResultsMutation>;
export type PrepareForResultsMutationResult = Apollo.MutationResult<GqlPrepareForResultsMutation>;
export type PrepareForResultsMutationOptions = Apollo.BaseMutationOptions<GqlPrepareForResultsMutation, GqlPrepareForResultsMutationVariables>;
export const TestKitSummaryGetTestKitsDocument = gql`
    query TestKitSummaryGetTestKits($testKitInput: TestKitInput) {
  testKits(input: $testKitInput) {
    testKits {
      ...TestKitSummaryTestKitFragment
    }
  }
}
    ${TestKitSummaryTestKitFragmentFragmentDoc}`;

/**
 * __useTestKitSummaryGetTestKitsQuery__
 *
 * To run a query within a React component, call `useTestKitSummaryGetTestKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestKitSummaryGetTestKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestKitSummaryGetTestKitsQuery({
 *   variables: {
 *      testKitInput: // value for 'testKitInput'
 *   },
 * });
 */
export function useTestKitSummaryGetTestKitsQuery(baseOptions?: Apollo.QueryHookOptions<GqlTestKitSummaryGetTestKitsQuery, GqlTestKitSummaryGetTestKitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlTestKitSummaryGetTestKitsQuery, GqlTestKitSummaryGetTestKitsQueryVariables>(TestKitSummaryGetTestKitsDocument, options);
      }
export function useTestKitSummaryGetTestKitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlTestKitSummaryGetTestKitsQuery, GqlTestKitSummaryGetTestKitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlTestKitSummaryGetTestKitsQuery, GqlTestKitSummaryGetTestKitsQueryVariables>(TestKitSummaryGetTestKitsDocument, options);
        }
export type TestKitSummaryGetTestKitsQueryHookResult = ReturnType<typeof useTestKitSummaryGetTestKitsQuery>;
export type TestKitSummaryGetTestKitsLazyQueryHookResult = ReturnType<typeof useTestKitSummaryGetTestKitsLazyQuery>;
export type TestKitSummaryGetTestKitsQueryResult = Apollo.QueryResult<GqlTestKitSummaryGetTestKitsQuery, GqlTestKitSummaryGetTestKitsQueryVariables>;
export const ViewedResultButtonViewedResultDocument = gql`
    mutation ViewedResultButtonViewedResult($input: MarkHasViewedResultInput!) {
  markHasViewedResult(input: $input) {
    id
  }
}
    `;
export type GqlViewedResultButtonViewedResultMutationFn = Apollo.MutationFunction<GqlViewedResultButtonViewedResultMutation, GqlViewedResultButtonViewedResultMutationVariables>;

/**
 * __useViewedResultButtonViewedResultMutation__
 *
 * To run a mutation, you first call `useViewedResultButtonViewedResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewedResultButtonViewedResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewedResultButtonViewedResultMutation, { data, loading, error }] = useViewedResultButtonViewedResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewedResultButtonViewedResultMutation(baseOptions?: Apollo.MutationHookOptions<GqlViewedResultButtonViewedResultMutation, GqlViewedResultButtonViewedResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlViewedResultButtonViewedResultMutation, GqlViewedResultButtonViewedResultMutationVariables>(ViewedResultButtonViewedResultDocument, options);
      }
export type ViewedResultButtonViewedResultMutationHookResult = ReturnType<typeof useViewedResultButtonViewedResultMutation>;
export type ViewedResultButtonViewedResultMutationResult = Apollo.MutationResult<GqlViewedResultButtonViewedResultMutation>;
export type ViewedResultButtonViewedResultMutationOptions = Apollo.BaseMutationOptions<GqlViewedResultButtonViewedResultMutation, GqlViewedResultButtonViewedResultMutationVariables>;
export const DashboardPageGetUserDocument = gql`
    query DashboardPageGetUser {
  userProfile {
    ...DashboardPageUserFragment
  }
}
    ${DashboardPageUserFragmentFragmentDoc}`;

/**
 * __useDashboardPageGetUserQuery__
 *
 * To run a query within a React component, call `useDashboardPageGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardPageGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GqlDashboardPageGetUserQuery, GqlDashboardPageGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDashboardPageGetUserQuery, GqlDashboardPageGetUserQueryVariables>(DashboardPageGetUserDocument, options);
      }
export function useDashboardPageGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDashboardPageGetUserQuery, GqlDashboardPageGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDashboardPageGetUserQuery, GqlDashboardPageGetUserQueryVariables>(DashboardPageGetUserDocument, options);
        }
export type DashboardPageGetUserQueryHookResult = ReturnType<typeof useDashboardPageGetUserQuery>;
export type DashboardPageGetUserLazyQueryHookResult = ReturnType<typeof useDashboardPageGetUserLazyQuery>;
export type DashboardPageGetUserQueryResult = Apollo.QueryResult<GqlDashboardPageGetUserQuery, GqlDashboardPageGetUserQueryVariables>;
export const DashboardGetTestKitsDocument = gql`
    query DashboardGetTestKits($testKitInput: TestKitInput) {
  testKits(input: $testKitInput) {
    testKits {
      ...TestKitSummaryTestKitFragment
    }
  }
}
    ${TestKitSummaryTestKitFragmentFragmentDoc}`;

/**
 * __useDashboardGetTestKitsQuery__
 *
 * To run a query within a React component, call `useDashboardGetTestKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardGetTestKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardGetTestKitsQuery({
 *   variables: {
 *      testKitInput: // value for 'testKitInput'
 *   },
 * });
 */
export function useDashboardGetTestKitsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDashboardGetTestKitsQuery, GqlDashboardGetTestKitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDashboardGetTestKitsQuery, GqlDashboardGetTestKitsQueryVariables>(DashboardGetTestKitsDocument, options);
      }
export function useDashboardGetTestKitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDashboardGetTestKitsQuery, GqlDashboardGetTestKitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDashboardGetTestKitsQuery, GqlDashboardGetTestKitsQueryVariables>(DashboardGetTestKitsDocument, options);
        }
export type DashboardGetTestKitsQueryHookResult = ReturnType<typeof useDashboardGetTestKitsQuery>;
export type DashboardGetTestKitsLazyQueryHookResult = ReturnType<typeof useDashboardGetTestKitsLazyQuery>;
export type DashboardGetTestKitsQueryResult = Apollo.QueryResult<GqlDashboardGetTestKitsQuery, GqlDashboardGetTestKitsQueryVariables>;
export const QuizPageFindByIdDocument = gql`
    query QuizPageFindById($input: ByIdInput) {
  findByID(input: $input) {
    ...QuizPageTestKitFragment
  }
}
    ${QuizPageTestKitFragmentFragmentDoc}`;

/**
 * __useQuizPageFindByIdQuery__
 *
 * To run a query within a React component, call `useQuizPageFindByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizPageFindByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizPageFindByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuizPageFindByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlQuizPageFindByIdQuery, GqlQuizPageFindByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlQuizPageFindByIdQuery, GqlQuizPageFindByIdQueryVariables>(QuizPageFindByIdDocument, options);
      }
export function useQuizPageFindByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlQuizPageFindByIdQuery, GqlQuizPageFindByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlQuizPageFindByIdQuery, GqlQuizPageFindByIdQueryVariables>(QuizPageFindByIdDocument, options);
        }
export type QuizPageFindByIdQueryHookResult = ReturnType<typeof useQuizPageFindByIdQuery>;
export type QuizPageFindByIdLazyQueryHookResult = ReturnType<typeof useQuizPageFindByIdLazyQuery>;
export type QuizPageFindByIdQueryResult = Apollo.QueryResult<GqlQuizPageFindByIdQuery, GqlQuizPageFindByIdQueryVariables>;
export const GetResultsPageDocument = gql`
    query GetResultsPage($input: TestKitInput) {
  testKits(input: $input) {
    testKits {
      ...ResultsPageTestKitFragment
    }
  }
}
    ${ResultsPageTestKitFragmentFragmentDoc}`;

/**
 * __useGetResultsPageQuery__
 *
 * To run a query within a React component, call `useGetResultsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetResultsPageQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetResultsPageQuery, GqlGetResultsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetResultsPageQuery, GqlGetResultsPageQueryVariables>(GetResultsPageDocument, options);
      }
export function useGetResultsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetResultsPageQuery, GqlGetResultsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetResultsPageQuery, GqlGetResultsPageQueryVariables>(GetResultsPageDocument, options);
        }
export type GetResultsPageQueryHookResult = ReturnType<typeof useGetResultsPageQuery>;
export type GetResultsPageLazyQueryHookResult = ReturnType<typeof useGetResultsPageLazyQuery>;
export type GetResultsPageQueryResult = Apollo.QueryResult<GqlGetResultsPageQuery, GqlGetResultsPageQueryVariables>;
export const SettingsSummaryItemUpdateUserDocument = gql`
    mutation SettingsSummaryItemUpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
  }
}
    `;
export type GqlSettingsSummaryItemUpdateUserMutationFn = Apollo.MutationFunction<GqlSettingsSummaryItemUpdateUserMutation, GqlSettingsSummaryItemUpdateUserMutationVariables>;

/**
 * __useSettingsSummaryItemUpdateUserMutation__
 *
 * To run a mutation, you first call `useSettingsSummaryItemUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsSummaryItemUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsSummaryItemUpdateUserMutation, { data, loading, error }] = useSettingsSummaryItemUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingsSummaryItemUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<GqlSettingsSummaryItemUpdateUserMutation, GqlSettingsSummaryItemUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlSettingsSummaryItemUpdateUserMutation, GqlSettingsSummaryItemUpdateUserMutationVariables>(SettingsSummaryItemUpdateUserDocument, options);
      }
export type SettingsSummaryItemUpdateUserMutationHookResult = ReturnType<typeof useSettingsSummaryItemUpdateUserMutation>;
export type SettingsSummaryItemUpdateUserMutationResult = Apollo.MutationResult<GqlSettingsSummaryItemUpdateUserMutation>;
export type SettingsSummaryItemUpdateUserMutationOptions = Apollo.BaseMutationOptions<GqlSettingsSummaryItemUpdateUserMutation, GqlSettingsSummaryItemUpdateUserMutationVariables>;
export const SettingsSummaryGetUserDocument = gql`
    query SettingsSummaryGetUser {
  userProfile {
    ...SettingsSummaryUserFragment
  }
}
    ${SettingsSummaryUserFragmentFragmentDoc}`;

/**
 * __useSettingsSummaryGetUserQuery__
 *
 * To run a query within a React component, call `useSettingsSummaryGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsSummaryGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsSummaryGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsSummaryGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GqlSettingsSummaryGetUserQuery, GqlSettingsSummaryGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlSettingsSummaryGetUserQuery, GqlSettingsSummaryGetUserQueryVariables>(SettingsSummaryGetUserDocument, options);
      }
export function useSettingsSummaryGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSettingsSummaryGetUserQuery, GqlSettingsSummaryGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlSettingsSummaryGetUserQuery, GqlSettingsSummaryGetUserQueryVariables>(SettingsSummaryGetUserDocument, options);
        }
export type SettingsSummaryGetUserQueryHookResult = ReturnType<typeof useSettingsSummaryGetUserQuery>;
export type SettingsSummaryGetUserLazyQueryHookResult = ReturnType<typeof useSettingsSummaryGetUserLazyQuery>;
export type SettingsSummaryGetUserQueryResult = Apollo.QueryResult<GqlSettingsSummaryGetUserQuery, GqlSettingsSummaryGetUserQueryVariables>;
export const GetTestKitHistoryDocument = gql`
    query GetTestKitHistory($input: TestKitInput) {
  testKits(input: $input) {
    testKits {
      ...TestKitHistoryTestKitFragment
    }
  }
}
    ${TestKitHistoryTestKitFragmentFragmentDoc}`;

/**
 * __useGetTestKitHistoryQuery__
 *
 * To run a query within a React component, call `useGetTestKitHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestKitHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestKitHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTestKitHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetTestKitHistoryQuery, GqlGetTestKitHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetTestKitHistoryQuery, GqlGetTestKitHistoryQueryVariables>(GetTestKitHistoryDocument, options);
      }
export function useGetTestKitHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetTestKitHistoryQuery, GqlGetTestKitHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetTestKitHistoryQuery, GqlGetTestKitHistoryQueryVariables>(GetTestKitHistoryDocument, options);
        }
export type GetTestKitHistoryQueryHookResult = ReturnType<typeof useGetTestKitHistoryQuery>;
export type GetTestKitHistoryLazyQueryHookResult = ReturnType<typeof useGetTestKitHistoryLazyQuery>;
export type GetTestKitHistoryQueryResult = Apollo.QueryResult<GqlGetTestKitHistoryQuery, GqlGetTestKitHistoryQueryVariables>;
export const UseCurrentUserGetUserDocument = gql`
    query useCurrentUserGetUser {
  userProfile {
    ...useCurrentUserUserFragment
  }
}
    ${UseCurrentUserUserFragmentFragmentDoc}`;

/**
 * __useUseCurrentUserGetUserQuery__
 *
 * To run a query within a React component, call `useUseCurrentUserGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCurrentUserGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCurrentUserGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseCurrentUserGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GqlUseCurrentUserGetUserQuery, GqlUseCurrentUserGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlUseCurrentUserGetUserQuery, GqlUseCurrentUserGetUserQueryVariables>(UseCurrentUserGetUserDocument, options);
      }
export function useUseCurrentUserGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUseCurrentUserGetUserQuery, GqlUseCurrentUserGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlUseCurrentUserGetUserQuery, GqlUseCurrentUserGetUserQueryVariables>(UseCurrentUserGetUserDocument, options);
        }
export type UseCurrentUserGetUserQueryHookResult = ReturnType<typeof useUseCurrentUserGetUserQuery>;
export type UseCurrentUserGetUserLazyQueryHookResult = ReturnType<typeof useUseCurrentUserGetUserLazyQuery>;
export type UseCurrentUserGetUserQueryResult = Apollo.QueryResult<GqlUseCurrentUserGetUserQuery, GqlUseCurrentUserGetUserQueryVariables>;
export const AppNotePageGetPatientDocument = gql`
    query AppNotePageGetPatient($input: ByIdInput) {
  patientById(input: $input) {
    ...AppNotePageUserFragment
  }
}
    ${AppNotePageUserFragmentFragmentDoc}`;

/**
 * __useAppNotePageGetPatientQuery__
 *
 * To run a query within a React component, call `useAppNotePageGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppNotePageGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppNotePageGetPatientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppNotePageGetPatientQuery(baseOptions?: Apollo.QueryHookOptions<GqlAppNotePageGetPatientQuery, GqlAppNotePageGetPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlAppNotePageGetPatientQuery, GqlAppNotePageGetPatientQueryVariables>(AppNotePageGetPatientDocument, options);
      }
export function useAppNotePageGetPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAppNotePageGetPatientQuery, GqlAppNotePageGetPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlAppNotePageGetPatientQuery, GqlAppNotePageGetPatientQueryVariables>(AppNotePageGetPatientDocument, options);
        }
export type AppNotePageGetPatientQueryHookResult = ReturnType<typeof useAppNotePageGetPatientQuery>;
export type AppNotePageGetPatientLazyQueryHookResult = ReturnType<typeof useAppNotePageGetPatientLazyQuery>;
export type AppNotePageGetPatientQueryResult = Apollo.QueryResult<GqlAppNotePageGetPatientQuery, GqlAppNotePageGetPatientQueryVariables>;
export const UpdateApptNotesDocument = gql`
    mutation UpdateApptNotes($input: UpdateApptNotesInput!) {
  updateApptNotes(input: $input) {
    id
  }
}
    `;
export type GqlUpdateApptNotesMutationFn = Apollo.MutationFunction<GqlUpdateApptNotesMutation, GqlUpdateApptNotesMutationVariables>;

/**
 * __useUpdateApptNotesMutation__
 *
 * To run a mutation, you first call `useUpdateApptNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApptNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApptNotesMutation, { data, loading, error }] = useUpdateApptNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApptNotesMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateApptNotesMutation, GqlUpdateApptNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateApptNotesMutation, GqlUpdateApptNotesMutationVariables>(UpdateApptNotesDocument, options);
      }
export type UpdateApptNotesMutationHookResult = ReturnType<typeof useUpdateApptNotesMutation>;
export type UpdateApptNotesMutationResult = Apollo.MutationResult<GqlUpdateApptNotesMutation>;
export type UpdateApptNotesMutationOptions = Apollo.BaseMutationOptions<GqlUpdateApptNotesMutation, GqlUpdateApptNotesMutationVariables>;
export const RegenerateReportDocument = gql`
    mutation RegenerateReport($input: RegenerateReportInput!) {
  regenerateReport(input: $input) {
    id
  }
}
    `;
export type GqlRegenerateReportMutationFn = Apollo.MutationFunction<GqlRegenerateReportMutation, GqlRegenerateReportMutationVariables>;

/**
 * __useRegenerateReportMutation__
 *
 * To run a mutation, you first call `useRegenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateReportMutation, { data, loading, error }] = useRegenerateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegenerateReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlRegenerateReportMutation, GqlRegenerateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlRegenerateReportMutation, GqlRegenerateReportMutationVariables>(RegenerateReportDocument, options);
      }
export type RegenerateReportMutationHookResult = ReturnType<typeof useRegenerateReportMutation>;
export type RegenerateReportMutationResult = Apollo.MutationResult<GqlRegenerateReportMutation>;
export type RegenerateReportMutationOptions = Apollo.BaseMutationOptions<GqlRegenerateReportMutation, GqlRegenerateReportMutationVariables>;
export const FinishApptNotesDocument = gql`
    mutation FinishApptNotes($input: FinishApptNotesInput!) {
  finishApptNotes(input: $input) {
    id
  }
}
    `;
export type GqlFinishApptNotesMutationFn = Apollo.MutationFunction<GqlFinishApptNotesMutation, GqlFinishApptNotesMutationVariables>;

/**
 * __useFinishApptNotesMutation__
 *
 * To run a mutation, you first call `useFinishApptNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishApptNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishApptNotesMutation, { data, loading, error }] = useFinishApptNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishApptNotesMutation(baseOptions?: Apollo.MutationHookOptions<GqlFinishApptNotesMutation, GqlFinishApptNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlFinishApptNotesMutation, GqlFinishApptNotesMutationVariables>(FinishApptNotesDocument, options);
      }
export type FinishApptNotesMutationHookResult = ReturnType<typeof useFinishApptNotesMutation>;
export type FinishApptNotesMutationResult = Apollo.MutationResult<GqlFinishApptNotesMutation>;
export type FinishApptNotesMutationOptions = Apollo.BaseMutationOptions<GqlFinishApptNotesMutation, GqlFinishApptNotesMutationVariables>;
export const CreatePatientMeetingNotesDocument = gql`
    mutation CreatePatientMeetingNotes($input: CreateNoteInput!) {
  createPatientNote(input: $input) {
    ...MeetingNotesFragment
  }
}
    ${MeetingNotesFragmentFragmentDoc}`;
export type GqlCreatePatientMeetingNotesMutationFn = Apollo.MutationFunction<GqlCreatePatientMeetingNotesMutation, GqlCreatePatientMeetingNotesMutationVariables>;

/**
 * __useCreatePatientMeetingNotesMutation__
 *
 * To run a mutation, you first call `useCreatePatientMeetingNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMeetingNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMeetingNotesMutation, { data, loading, error }] = useCreatePatientMeetingNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientMeetingNotesMutation(baseOptions?: Apollo.MutationHookOptions<GqlCreatePatientMeetingNotesMutation, GqlCreatePatientMeetingNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlCreatePatientMeetingNotesMutation, GqlCreatePatientMeetingNotesMutationVariables>(CreatePatientMeetingNotesDocument, options);
      }
export type CreatePatientMeetingNotesMutationHookResult = ReturnType<typeof useCreatePatientMeetingNotesMutation>;
export type CreatePatientMeetingNotesMutationResult = Apollo.MutationResult<GqlCreatePatientMeetingNotesMutation>;
export type CreatePatientMeetingNotesMutationOptions = Apollo.BaseMutationOptions<GqlCreatePatientMeetingNotesMutation, GqlCreatePatientMeetingNotesMutationVariables>;
export const GetPatientMeetingNotesDocument = gql`
    query GetPatientMeetingNotes($input: NoteInput) {
  patientNotes(input: $input) {
    notes {
      ...MeetingNotesFragment
    }
  }
}
    ${MeetingNotesFragmentFragmentDoc}`;

/**
 * __useGetPatientMeetingNotesQuery__
 *
 * To run a query within a React component, call `useGetPatientMeetingNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientMeetingNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientMeetingNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientMeetingNotesQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetPatientMeetingNotesQuery, GqlGetPatientMeetingNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetPatientMeetingNotesQuery, GqlGetPatientMeetingNotesQueryVariables>(GetPatientMeetingNotesDocument, options);
      }
export function useGetPatientMeetingNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetPatientMeetingNotesQuery, GqlGetPatientMeetingNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetPatientMeetingNotesQuery, GqlGetPatientMeetingNotesQueryVariables>(GetPatientMeetingNotesDocument, options);
        }
export type GetPatientMeetingNotesQueryHookResult = ReturnType<typeof useGetPatientMeetingNotesQuery>;
export type GetPatientMeetingNotesLazyQueryHookResult = ReturnType<typeof useGetPatientMeetingNotesLazyQuery>;
export type GetPatientMeetingNotesQueryResult = Apollo.QueryResult<GqlGetPatientMeetingNotesQuery, GqlGetPatientMeetingNotesQueryVariables>;
export const PatientResultsButtonsReleaseResultsDocument = gql`
    mutation PatientResultsButtonsReleaseResults($input: ReleaseTestKitResultsInput!) {
  releaseTestKitResults(input: $input) {
    id
    resultsStatus
  }
}
    `;
export type GqlPatientResultsButtonsReleaseResultsMutationFn = Apollo.MutationFunction<GqlPatientResultsButtonsReleaseResultsMutation, GqlPatientResultsButtonsReleaseResultsMutationVariables>;

/**
 * __usePatientResultsButtonsReleaseResultsMutation__
 *
 * To run a mutation, you first call `usePatientResultsButtonsReleaseResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientResultsButtonsReleaseResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientResultsButtonsReleaseResultsMutation, { data, loading, error }] = usePatientResultsButtonsReleaseResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientResultsButtonsReleaseResultsMutation(baseOptions?: Apollo.MutationHookOptions<GqlPatientResultsButtonsReleaseResultsMutation, GqlPatientResultsButtonsReleaseResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlPatientResultsButtonsReleaseResultsMutation, GqlPatientResultsButtonsReleaseResultsMutationVariables>(PatientResultsButtonsReleaseResultsDocument, options);
      }
export type PatientResultsButtonsReleaseResultsMutationHookResult = ReturnType<typeof usePatientResultsButtonsReleaseResultsMutation>;
export type PatientResultsButtonsReleaseResultsMutationResult = Apollo.MutationResult<GqlPatientResultsButtonsReleaseResultsMutation>;
export type PatientResultsButtonsReleaseResultsMutationOptions = Apollo.BaseMutationOptions<GqlPatientResultsButtonsReleaseResultsMutation, GqlPatientResultsButtonsReleaseResultsMutationVariables>;
export const PatientsListComponentGetPatientsDocument = gql`
    query PatientsListComponentGetPatients($PatientsInput: PatientsInput) {
  patients(input: $PatientsInput) {
    ...PatientsListComponentPatientFragment
  }
}
    ${PatientsListComponentPatientFragmentFragmentDoc}`;

/**
 * __usePatientsListComponentGetPatientsQuery__
 *
 * To run a query within a React component, call `usePatientsListComponentGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsListComponentGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsListComponentGetPatientsQuery({
 *   variables: {
 *      PatientsInput: // value for 'PatientsInput'
 *   },
 * });
 */
export function usePatientsListComponentGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GqlPatientsListComponentGetPatientsQuery, GqlPatientsListComponentGetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPatientsListComponentGetPatientsQuery, GqlPatientsListComponentGetPatientsQueryVariables>(PatientsListComponentGetPatientsDocument, options);
      }
export function usePatientsListComponentGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPatientsListComponentGetPatientsQuery, GqlPatientsListComponentGetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPatientsListComponentGetPatientsQuery, GqlPatientsListComponentGetPatientsQueryVariables>(PatientsListComponentGetPatientsDocument, options);
        }
export type PatientsListComponentGetPatientsQueryHookResult = ReturnType<typeof usePatientsListComponentGetPatientsQuery>;
export type PatientsListComponentGetPatientsLazyQueryHookResult = ReturnType<typeof usePatientsListComponentGetPatientsLazyQuery>;
export type PatientsListComponentGetPatientsQueryResult = Apollo.QueryResult<GqlPatientsListComponentGetPatientsQuery, GqlPatientsListComponentGetPatientsQueryVariables>;
export const ViewQuestionnareButtonGetProfileFromS3Document = gql`
    mutation ViewQuestionnareButtonGetProfileFromS3($input: GetProfileFromS3Input!) {
  getProfileFromS3(input: $input) {
    items {
      key
      value
      values
      comment
    }
  }
}
    `;
export type GqlViewQuestionnareButtonGetProfileFromS3MutationFn = Apollo.MutationFunction<GqlViewQuestionnareButtonGetProfileFromS3Mutation, GqlViewQuestionnareButtonGetProfileFromS3MutationVariables>;

/**
 * __useViewQuestionnareButtonGetProfileFromS3Mutation__
 *
 * To run a mutation, you first call `useViewQuestionnareButtonGetProfileFromS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewQuestionnareButtonGetProfileFromS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewQuestionnareButtonGetProfileFromS3Mutation, { data, loading, error }] = useViewQuestionnareButtonGetProfileFromS3Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewQuestionnareButtonGetProfileFromS3Mutation(baseOptions?: Apollo.MutationHookOptions<GqlViewQuestionnareButtonGetProfileFromS3Mutation, GqlViewQuestionnareButtonGetProfileFromS3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlViewQuestionnareButtonGetProfileFromS3Mutation, GqlViewQuestionnareButtonGetProfileFromS3MutationVariables>(ViewQuestionnareButtonGetProfileFromS3Document, options);
      }
export type ViewQuestionnareButtonGetProfileFromS3MutationHookResult = ReturnType<typeof useViewQuestionnareButtonGetProfileFromS3Mutation>;
export type ViewQuestionnareButtonGetProfileFromS3MutationResult = Apollo.MutationResult<GqlViewQuestionnareButtonGetProfileFromS3Mutation>;
export type ViewQuestionnareButtonGetProfileFromS3MutationOptions = Apollo.BaseMutationOptions<GqlViewQuestionnareButtonGetProfileFromS3Mutation, GqlViewQuestionnareButtonGetProfileFromS3MutationVariables>;
export const GetUserAuditLogsDocument = gql`
    query GetUserAuditLogs($input: AuditLogInput) {
  auditLogs(input: $input) {
    auditLogs {
      ...AuditLogsFragment
    }
  }
}
    ${AuditLogsFragmentFragmentDoc}`;

/**
 * __useGetUserAuditLogsQuery__
 *
 * To run a query within a React component, call `useGetUserAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuditLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserAuditLogsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetUserAuditLogsQuery, GqlGetUserAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetUserAuditLogsQuery, GqlGetUserAuditLogsQueryVariables>(GetUserAuditLogsDocument, options);
      }
export function useGetUserAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetUserAuditLogsQuery, GqlGetUserAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetUserAuditLogsQuery, GqlGetUserAuditLogsQueryVariables>(GetUserAuditLogsDocument, options);
        }
export type GetUserAuditLogsQueryHookResult = ReturnType<typeof useGetUserAuditLogsQuery>;
export type GetUserAuditLogsLazyQueryHookResult = ReturnType<typeof useGetUserAuditLogsLazyQuery>;
export type GetUserAuditLogsQueryResult = Apollo.QueryResult<GqlGetUserAuditLogsQuery, GqlGetUserAuditLogsQueryVariables>;
export const GetUserTestKitsDocument = gql`
    query GetUserTestKits($input: TestKitInput) {
  testKits(input: $input) {
    testKits {
      ...PatientAuditHistoryPageTestKitsFragment
    }
  }
}
    ${PatientAuditHistoryPageTestKitsFragmentFragmentDoc}`;

/**
 * __useGetUserTestKitsQuery__
 *
 * To run a query within a React component, call `useGetUserTestKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTestKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTestKitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserTestKitsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetUserTestKitsQuery, GqlGetUserTestKitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetUserTestKitsQuery, GqlGetUserTestKitsQueryVariables>(GetUserTestKitsDocument, options);
      }
export function useGetUserTestKitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetUserTestKitsQuery, GqlGetUserTestKitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetUserTestKitsQuery, GqlGetUserTestKitsQueryVariables>(GetUserTestKitsDocument, options);
        }
export type GetUserTestKitsQueryHookResult = ReturnType<typeof useGetUserTestKitsQuery>;
export type GetUserTestKitsLazyQueryHookResult = ReturnType<typeof useGetUserTestKitsLazyQuery>;
export type GetUserTestKitsQueryResult = Apollo.QueryResult<GqlGetUserTestKitsQuery, GqlGetUserTestKitsQueryVariables>;
export const PatientAuditHistoryPageGetUserDocument = gql`
    query PatientAuditHistoryPageGetUser($input: ByIdInput) {
  userById(input: $input) {
    ...PatientAuditHistoryPageUserFragment
  }
}
    ${PatientAuditHistoryPageUserFragmentFragmentDoc}`;

/**
 * __usePatientAuditHistoryPageGetUserQuery__
 *
 * To run a query within a React component, call `usePatientAuditHistoryPageGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAuditHistoryPageGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAuditHistoryPageGetUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientAuditHistoryPageGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GqlPatientAuditHistoryPageGetUserQuery, GqlPatientAuditHistoryPageGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPatientAuditHistoryPageGetUserQuery, GqlPatientAuditHistoryPageGetUserQueryVariables>(PatientAuditHistoryPageGetUserDocument, options);
      }
export function usePatientAuditHistoryPageGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPatientAuditHistoryPageGetUserQuery, GqlPatientAuditHistoryPageGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPatientAuditHistoryPageGetUserQuery, GqlPatientAuditHistoryPageGetUserQueryVariables>(PatientAuditHistoryPageGetUserDocument, options);
        }
export type PatientAuditHistoryPageGetUserQueryHookResult = ReturnType<typeof usePatientAuditHistoryPageGetUserQuery>;
export type PatientAuditHistoryPageGetUserLazyQueryHookResult = ReturnType<typeof usePatientAuditHistoryPageGetUserLazyQuery>;
export type PatientAuditHistoryPageGetUserQueryResult = Apollo.QueryResult<GqlPatientAuditHistoryPageGetUserQuery, GqlPatientAuditHistoryPageGetUserQueryVariables>;
export const PatientProfilePageGetPatientDocument = gql`
    query PatientProfilePageGetPatient($input: ByIdInput) {
  patientById(input: $input) {
    ...PatientProfilePageUserFragment
  }
}
    ${PatientProfilePageUserFragmentFragmentDoc}`;

/**
 * __usePatientProfilePageGetPatientQuery__
 *
 * To run a query within a React component, call `usePatientProfilePageGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientProfilePageGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientProfilePageGetPatientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientProfilePageGetPatientQuery(baseOptions?: Apollo.QueryHookOptions<GqlPatientProfilePageGetPatientQuery, GqlPatientProfilePageGetPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlPatientProfilePageGetPatientQuery, GqlPatientProfilePageGetPatientQueryVariables>(PatientProfilePageGetPatientDocument, options);
      }
export function usePatientProfilePageGetPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPatientProfilePageGetPatientQuery, GqlPatientProfilePageGetPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlPatientProfilePageGetPatientQuery, GqlPatientProfilePageGetPatientQueryVariables>(PatientProfilePageGetPatientDocument, options);
        }
export type PatientProfilePageGetPatientQueryHookResult = ReturnType<typeof usePatientProfilePageGetPatientQuery>;
export type PatientProfilePageGetPatientLazyQueryHookResult = ReturnType<typeof usePatientProfilePageGetPatientLazyQuery>;
export type PatientProfilePageGetPatientQueryResult = Apollo.QueryResult<GqlPatientProfilePageGetPatientQuery, GqlPatientProfilePageGetPatientQueryVariables>;
export const QuestionnairePageGetTestKitByIdDocument = gql`
    query QuestionnairePageGetTestKitById($input: ByIdInput) {
  findByID(input: $input) {
    ...QuestionnairePageTestKit
  }
}
    ${QuestionnairePageTestKitFragmentDoc}`;

/**
 * __useQuestionnairePageGetTestKitByIdQuery__
 *
 * To run a query within a React component, call `useQuestionnairePageGetTestKitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairePageGetTestKitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairePageGetTestKitByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnairePageGetTestKitByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlQuestionnairePageGetTestKitByIdQuery, GqlQuestionnairePageGetTestKitByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlQuestionnairePageGetTestKitByIdQuery, GqlQuestionnairePageGetTestKitByIdQueryVariables>(QuestionnairePageGetTestKitByIdDocument, options);
      }
export function useQuestionnairePageGetTestKitByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlQuestionnairePageGetTestKitByIdQuery, GqlQuestionnairePageGetTestKitByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlQuestionnairePageGetTestKitByIdQuery, GqlQuestionnairePageGetTestKitByIdQueryVariables>(QuestionnairePageGetTestKitByIdDocument, options);
        }
export type QuestionnairePageGetTestKitByIdQueryHookResult = ReturnType<typeof useQuestionnairePageGetTestKitByIdQuery>;
export type QuestionnairePageGetTestKitByIdLazyQueryHookResult = ReturnType<typeof useQuestionnairePageGetTestKitByIdLazyQuery>;
export type QuestionnairePageGetTestKitByIdQueryResult = Apollo.QueryResult<GqlQuestionnairePageGetTestKitByIdQuery, GqlQuestionnairePageGetTestKitByIdQueryVariables>;
export const QuizPageGetTestKitsDocument = gql`
    query QuizPageGetTestKits($input: TestKitInput) {
  testKits(input: $input) {
    testKits {
      ...QuizPageTestKitFragment
    }
  }
}
    ${QuizPageTestKitFragmentFragmentDoc}`;

/**
 * __useQuizPageGetTestKitsQuery__
 *
 * To run a query within a React component, call `useQuizPageGetTestKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizPageGetTestKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizPageGetTestKitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuizPageGetTestKitsQuery(baseOptions?: Apollo.QueryHookOptions<GqlQuizPageGetTestKitsQuery, GqlQuizPageGetTestKitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlQuizPageGetTestKitsQuery, GqlQuizPageGetTestKitsQueryVariables>(QuizPageGetTestKitsDocument, options);
      }
export function useQuizPageGetTestKitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlQuizPageGetTestKitsQuery, GqlQuizPageGetTestKitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlQuizPageGetTestKitsQuery, GqlQuizPageGetTestKitsQueryVariables>(QuizPageGetTestKitsDocument, options);
        }
export type QuizPageGetTestKitsQueryHookResult = ReturnType<typeof useQuizPageGetTestKitsQuery>;
export type QuizPageGetTestKitsLazyQueryHookResult = ReturnType<typeof useQuizPageGetTestKitsLazyQuery>;
export type QuizPageGetTestKitsQueryResult = Apollo.QueryResult<GqlQuizPageGetTestKitsQuery, GqlQuizPageGetTestKitsQueryVariables>;
export const UpdateQuestionnaireDocument = gql`
    mutation UpdateQuestionnaire($input: CreateOrUpdateQuestionnaireInput!) {
  createOrUpdateQuestionnaire(input: $input) {
    id
  }
}
    `;
export type GqlUpdateQuestionnaireMutationFn = Apollo.MutationFunction<GqlUpdateQuestionnaireMutation, GqlUpdateQuestionnaireMutationVariables>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateQuestionnaireMutation, GqlUpdateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GqlUpdateQuestionnaireMutation, GqlUpdateQuestionnaireMutationVariables>(UpdateQuestionnaireDocument, options);
      }
export type UpdateQuestionnaireMutationHookResult = ReturnType<typeof useUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationResult = Apollo.MutationResult<GqlUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<GqlUpdateQuestionnaireMutation, GqlUpdateQuestionnaireMutationVariables>;
export const QuestionnaireProviderQuestionnaireByTestKitIdDocument = gql`
    query QuestionnaireProviderQuestionnaireByTestKitId($input: ByIdInput) {
  questionnaireByTestKitId(input: $input) {
    ...QuestionnaireProviderQuestionnaireFragment
  }
}
    ${QuestionnaireProviderQuestionnaireFragmentFragmentDoc}`;

/**
 * __useQuestionnaireProviderQuestionnaireByTestKitIdQuery__
 *
 * To run a query within a React component, call `useQuestionnaireProviderQuestionnaireByTestKitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireProviderQuestionnaireByTestKitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireProviderQuestionnaireByTestKitIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireProviderQuestionnaireByTestKitIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlQuestionnaireProviderQuestionnaireByTestKitIdQuery, GqlQuestionnaireProviderQuestionnaireByTestKitIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlQuestionnaireProviderQuestionnaireByTestKitIdQuery, GqlQuestionnaireProviderQuestionnaireByTestKitIdQueryVariables>(QuestionnaireProviderQuestionnaireByTestKitIdDocument, options);
      }
export function useQuestionnaireProviderQuestionnaireByTestKitIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlQuestionnaireProviderQuestionnaireByTestKitIdQuery, GqlQuestionnaireProviderQuestionnaireByTestKitIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlQuestionnaireProviderQuestionnaireByTestKitIdQuery, GqlQuestionnaireProviderQuestionnaireByTestKitIdQueryVariables>(QuestionnaireProviderQuestionnaireByTestKitIdDocument, options);
        }
export type QuestionnaireProviderQuestionnaireByTestKitIdQueryHookResult = ReturnType<typeof useQuestionnaireProviderQuestionnaireByTestKitIdQuery>;
export type QuestionnaireProviderQuestionnaireByTestKitIdLazyQueryHookResult = ReturnType<typeof useQuestionnaireProviderQuestionnaireByTestKitIdLazyQuery>;
export type QuestionnaireProviderQuestionnaireByTestKitIdQueryResult = Apollo.QueryResult<GqlQuestionnaireProviderQuestionnaireByTestKitIdQuery, GqlQuestionnaireProviderQuestionnaireByTestKitIdQueryVariables>;
export const DownloadReportDocument = gql`
    query downloadReport($input: ReportLinkInput!) {
  getReportLink(input: $input) {
    preSignedLink
  }
}
    `;

/**
 * __useDownloadReportQuery__
 *
 * To run a query within a React component, call `useDownloadReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadReportQuery(baseOptions: Apollo.QueryHookOptions<GqlDownloadReportQuery, GqlDownloadReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDownloadReportQuery, GqlDownloadReportQueryVariables>(DownloadReportDocument, options);
      }
export function useDownloadReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDownloadReportQuery, GqlDownloadReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDownloadReportQuery, GqlDownloadReportQueryVariables>(DownloadReportDocument, options);
        }
export type DownloadReportQueryHookResult = ReturnType<typeof useDownloadReportQuery>;
export type DownloadReportLazyQueryHookResult = ReturnType<typeof useDownloadReportLazyQuery>;
export type DownloadReportQueryResult = Apollo.QueryResult<GqlDownloadReportQuery, GqlDownloadReportQueryVariables>;